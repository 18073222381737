svg.circleLoaderSvg {
    animation: zoominout 1s ;
  }
  @keyframes zoominout {
      0% {
          transform: scale(0,0);
      }
      100% {
          transform: scale(1,1);
      }
  } 
  
  .circle1{
  stroke-dasharray: 28 20;
      stroke-dashoffset: 10;
  stroke-linecap: round;
    animation: rotate linear 1s infinite;
    display: block !important;
    transform-origin: unset;
    
  }
  .circle2{
  stroke-dasharray: 13; 
  stroke-dashoffset: 0; 
  stroke-linecap: round;
    animation: rotateLeft linear 2s infinite;
    display: block !important;
    transform-origin: unset;
  }
  .circle3{
  stroke-dasharray: 21; 
  stroke-dashoffset: 0; 
    animation: rotate linear 2.5s infinite;
  stroke-linecap: round;
    display: block !important;
    transform-origin: unset;
  }
  @keyframes rotate {
      0% {
          transform: rotate(0deg);
      }
      50% {
          transform: rotate(180deg);
      }
      100% {
          transform: rotate(360deg);
      }
  }
  
  @keyframes rotateLeft {
      0% {
          transform: rotate(360deg);
      }
      50% {
          transform: rotate(180deg);
      }
      100% {
          transform: rotate(0deg);
      }
  }