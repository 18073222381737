
.txtAreaNumbers{
    background: url('../../assets/images/123New.png');
    background-attachment: local;
    background-repeat: no-repeat;
    padding-left: 55px !important;
    padding-top: 5px !important;
    border-color:#D2D3D4;
    border-radius: 4px;
    width: 100%;
    resize: vertical;
}