/*** Geneneral CSS ***/
* { scroll-behavior: smooth; scrollbar-width: thin;}
html{ height: 100%;scroll-behavior: smooth;}
#root{ height: 100%; }
body{ height: 100%; color:#110308; line-height:1.3; background-color: #F7F7F7!important; font-size:14px; font-weight:400;overflow: hidden; }
.cke_button__bold_icon {
  background: url('../../assets/images/Success.png') no-repeat 0 -24px !important;
}
body .cke_editable { background-color: red !important; }

/*** Padding  ***/
.p-0 { padding: 0px !important;}
.p5 { padding: 5px !important; }
.pb5 { padding-bottom: 5px !important; }
.p-1 {  padding: 10px !important;}
.p-2 {  padding: 20px !important;}
.p-3 {  padding: 30px !important;}
.p-4 {  padding: 40px !important;}
.p-5 {  padding: 50px !important;}
.p-10 {  padding: 100px !important;}
.pt-0 {  padding-top: 0px !important;}
.pt5 { padding-top: 5px !important }
.pt-1 {  padding-top: 10px !important;}
.pt15 { padding-top: 15px !important; }
.pt-2 {  padding-top: 20px !important;}
.pt-3 {  padding-top: 30px !important;}
.pt-4 {  padding-top: 40px !important;}
.pt-5 {  padding-top: 50px !important;}
.pt-6 {  padding-top: 60px !important;}
.pb-0 {  padding-bottom: 0px !important;}
.pb-1 {  padding-bottom: 10px !important;}
.pb15 {  padding-bottom: 15px !important;}
.pb-2 {  padding-bottom: 20px !important;}
.pb-3 {  padding-bottom: 30px !important;}
.pb-4 {  padding-bottom: 40px !important;}
.pb-5 {  padding-bottom: 50px !important;}
.pb-6 {  padding-bottom: 60px !important;}
.pb-7 {  padding-bottom: 60px !important;}
.pl-1 {  padding-left: 10px !important;}
.pl5 {  padding-left: 5px !important;}
.pl-2 {  padding-left: 20px !important;}
.pl-3 {  padding-left: 30px !important;}
.pl-4 {  padding-left: 40px !important;}
.pl-5 {  padding-left: 50px !important;}
.pl-6 {  padding-left: 60px !important;}
.pl-10 {  padding-left: 100px !important;}
.pr-0 {  padding-right: 0px !important;}
.pr-1 {  padding-right: 10px !important;}
.pr15 { padding-right: 15px !important; }
.pr-2 {  padding-right: 20px !important;}
.pr-3 {  padding-right: 30px !important;}
.pr-4 {  padding-right: 40px !important;}
.pr-5 {  padding-right: 50px !important;}
.pr-6 {  padding-right: 60px !important;}
.pr-10 {  padding-right: 100px !important;}


/*** Margin  ***/
.m-0 {  margin: 0px !important;}
.mr5 { margin-right: 5px !important }
.m-1 {  margin: 10px !important;}
.m-2 {  margin: 20px !important;}
.m-3 {  margin: 30px !important;}
.m-4 {  margin: 40px !important;}
.m-5 {  margin: 50px !important;}
.m-6 {  margin: 60px !important;}
.mt-0 {  margin-top: 0px !important;}
.mt-1 {  margin-top: 10px !important;}
.mt-2 {  margin-top: 20px !important;}
.mt-3 {  margin-top: 30px !important;}
.mt-4 {  margin-top: 40px !important;}
.mt-5 {  margin-top: 40px !important;}
.mt-15 {  margin-top: 15px !important;}
.mt-6 {  margin-top: 60px !important;}
.mb-0 {  margin-bottom: 0px !important;}
.mb-1 {  margin-bottom: 10px !important;}
.mb15 {  margin-bottom: 15px !important;}
.mb-2 {  margin-bottom: 20px !important;}
.mb-3 {  margin-bottom: 30px !important;}
.mb-4 {  margin-bottom: 40px !important;}
.mb-6 {  margin-bottom: 60px !important;}
.mb-7 {  margin-bottom: 70px !important;}
.mb-8 {  margin-bottom: 80px !important;}
.ml-0 {  margin-left: 0px !important;}
.ml-1 {  margin-left: 10px !important;}
.ml-2 {  margin-left: 20px !important;}
.ml-3 {  margin-left: 30px !important;}
.ml-4 {  margin-left: 40px !important;}
.ml-5 {  margin-left: 50px !important;}
.ml-6 {  margin-left: 60px !important;}
.mr-0 {  margin-right: 0px !important;}
.mr-1 {  margin-right: 10px !important;}
.mr-2 {  margin-right: 20px !important;}
.mr-3 {  margin-right: 30px !important;}
.mr-4 {  margin-right: 40px !important;}
.mr-5 {  margin-right: 50px !important;}
.mr-6 {  margin-right: 60px !important;}

.h_100, .h-100 {  height: 100%;}
.w-100 { width: 100% }
.opacity0 { opacity: 0 }
.cursorPointer { cursor:pointer} 
.relative { position: relative }
.grow { flex-grow: 1; }
.textFieldLabel { padding-bottom: 2px; color: #110308 !important;}
.flex { display: flex }
.alignCenter { align-items: center }
.imgFluid { width:100% }
.flexStart { align-items: flex-start }
.textarea { padding: 8px !important;
  font-size: 14px;
  font-family: Open Sans;
  border-color: #D2D3D4;
  border-radius: 4px;
} 
textarea { padding: 8px !important;
  font-size: 14px;
  font-family: Open Sans;
  border-color: #D2D3D4;
  border-radius: 4px;
} 
.textarea:active { padding: 7px !important }
.upperCase { text-transform: uppercase; }
.transCapital { text-transform: capitalize !important; }
.overflowY { overflow-y: auto !important; }
.marginAuto { margin: auto !important }
.paperBgGray .MuiPaper-root { background: #F7F7F7; }
.drawerHeader { top: 0; position: sticky; background: #fff; z-index: 1;}
.drawerFooter{ bottom: 0; position: sticky; background: #fff; z-index: 1;}
.link:hover { color: #12A8DE !important }
.inlineBlock { display: inline-block !important; }
.reqStar { padding-left: 5px }
.MuiListItem-root { min-height: 24px !important; }
/* .grow {flexGrow: 1;} */
.bgGray .editor-class { background-color: #fafafa !important; }
.bgGray textarea { background-color: #fafafa !important; }

/** Colors */
.bg-white{ background-color:#fff !important; }

/* ScrollBar */
::-webkit-scrollbar { width: 6px; height: 6px }
::-webkit-scrollbar-track { background: #f1f1f1;border-radius: 4px; }
::-webkit-scrollbar-thumb { background: #888;border-radius: 4px; }
::-webkit-scrollbar-thumb:hover { background: #555; }
.width-100{
  width: 100%;
}
.width-auto{
  width: auto;
}

/* Verticle 3 Line ellipsis */
.threeLineEllip {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    white-space: normal;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.twoLineEllip {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    white-space: normal;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.oneLineEllip, .listOneLine .MuiTypography-root {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    display: block;
    max-width: 100%;
    white-space: nowrap;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.maxHeight2_Lineh6 {
  max-height: 50px
}
.maxHeight3_Line_body1 {
  max-height: 63px
}
/* custom Spacing */
@media only screen and (min-width: 960px) {
 .tenantTitle {
   text-align: right !important;
 }
}
@media only screen and (max-width: 960px) {
  .tenantTitle {
    /* margin-top: 10px !important; */
  }
 }


/* conatct Form*/
@media only screen and (max-width: 1400px) {
  .contactForm {
    height: calc(100vh - 324px);
    overflow-y: auto;
  }
}
@media only screen and (max-width: 576px) {
.smShow {
  display: none !important;
}
.tenantFooterDetail {
  padding: 8px 15px 8px 15px ;
}
.tenantFooter {
  padding: 0 !important;
}
.tenantFooter {
  height: 56px !important;
}
.EnduserStepper  {
  display: none !important;
}
.tenantCardContainer {
  /* margin-bottom: 172px !important; */
}
.sm-spacing15 .MuiGrid-grid-xs-12
{
  margin-bottom: 10px;
}
.addressContainer {
  padding-left: 0px !important;
  border: none !important;
}
.xs-pt-1{ 
  padding-top: 10px;
}
.xs-pb-1 {
  padding-bottom: 10px;
}
.mobileShow {
  display: block;
}
.tenantTitle {
  font-size: 16px !important;
}
.EnduserStepper .MuiStepConnector-alternativeLabel {
  display: none;
}
.EnduserStepper .MuiStepLabel-root.MuiStepLabel-alternativeLabel {
  flex-direction: column !important;
  padding: 10px 0 10px 0 !important;
}
.EnduserStepper .MuiStep-horizontal {
  padding-left: 3px;
    padding-right: 4px;
}
.EnduserStepper .MuiStepLabel-label{
  margin-left: 0px !important;
}
}
.addressContainer .LocationIcon
{
  min-width: 19px;
  min-height: 19px;
}
.addressContainer .HomeIcon, .addressContainer .TelephoneIcon, .addressContainer .LocationIcon {
  margin-right: 10px;
  }
  .addressContainer .LocationIcon {
    margin-left: -2px !important;
  }
  .addressDrawer .LocationIcon
{
  min-width: 19px;
  min-height: 19px;
}
.addressDrawer .HomeIcon, .addressDrawer .TelephoneIcon, .addressDrawer .LocationIcon {
  margin-right: 10px;
  }

@media only screen and (min-width: 576px) {
  .sm-pl-1 {
    padding-left: 10px;
  }
  .sm-spacing15 {
    width: calc(100% + 120px) !important;
    margin-left: -60px !important;
    margin-right: -60px !important;
  }
  .sm-spacing15 > .MuiGrid-item {
    padding: 0 60px !important;
    margin-bottom: 20px !important;
  }
  
  .mobileShow {
    display: none !important;
  }
  .sm-p-3 {
    padding: 30px !important;
  }
  .sm-mb-8 {
    margin-bottom: 80px !important;
  }
  .sm-mb-3 {
    margin-bottom: 30px !important;
  }
  }

  @media only screen and (max-width: 1100px) {
   .tenantTitle {
     padding-top: 2px;
   }
    }

    .importField .MuiOutlinedInput-root {
      height: 34px !important;
      min-height: 34px !important;
    }
    .mt3 .MuiOutlinedInput-root .MuiSvgIcon-root {
      margin-top: 3px;
      margin-right: -3px;
    }


.sortable-list-tab {
    cursor: default;
    visibility: visible;
    z-index: 99999999;
    list-style-type: none;
    padding: .3em;
}

.internet-error {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.8);
    margin-top: 0;
    font-size: 20px;
    z-index: 9999999;
    top: 0;
    left: 0;
  }
  
  .internet-error p {
    position: absolute;
    top:0;
    left:0;
    right: 0;
    bottom: 0;
    margin: auto;
    font-size: 25px;
    line-height: 60px;
    color: #000;
    text-align: center;
    font-weight: bold;
    width: auto;
    height: 100px;
  }

  .internet-erro {
    text-align: center;
    color: red;
    font-size: 20px;
  }

  .displayNone .MuiAutocomplete-endAdornment {
    display: none;
  }
  
  .multiLineText .MuiOutlinedInput-root, .multiLineText .MuiFormControl-root  {
    height: unset !important;
    border-radius: 6px !important;
  }

  .chipboderRadius10 .MuiOutlinedInput-root {
    border-radius: 10px !important;
  }


  /* UserCards */
  .userTile {
    /* max-width: 292px !important; */
  }
  .userCardTitle {
  max-width: 157px;
  }
  .UsersCardsTxtWidth {
    max-width: 254px
  }


  /* Service Cards   card Width = 294 */

  .serviceCardTitle {
    max-width: 157px;
  }
    .serviceCardsTxtWidth {
      max-width: 240px;
      text-align: center;
    }


 /* Email Cards   card Width= 253*/

 .EmailCardTitle {
  max-width: 157px;
}
  .EmailCardsTxtWidth {
    max-width: 240px;
    text-align: center;
  }

  /* .test{
    background-color: "red";
  } */
  .toTop {
    position: fixed !important;
    z-index: 1;
    right: 30px;
    bottom: 20px;
    background: #1A385E !important;
    box-shadow: 0 0 8px #8d8d8d;
    padding: 3px !important;
}
.toTop .MuiSvgIcon-root {
  fill: #fff !important;
}

.successBg {
  background-image: url(../images/Success2.gif);
  background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 380px;
    width: 380px;
    border-radius: 100%;
}
.check-class {
  max-height: 35vh !important;
}
.check-class 
  [aria-selected="true"] {  
    background-color: #f1f1f1 !important;
    border-color: transparent;
  }


.searchBoxGridHide {
  /* display: none; */
  width: 0px !important;
  opacity: 0;
  transition: width .4s ease-in-out;
}
.searchBoxGridShow {
  opacity: 1;
  margin-right: -33px;
  width: 200px !important;
  display: block !important;
  transition: width .4s ease-in-out;
}
  .Btnloader .MuiCircularProgress-colorPrimary {
    color: #fff;
    animation: MuiCircularProgress-keyframes-circular-rotate .4s linear infinite;
    width: 30px !important;
    height: 30px !important;
  }
  .Btnloader svg {
    width: 30px;
    height: 30px;
  }
  .Btnloader circle {
    stroke-width: 4.6;
  }

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
  display: none;
}
input[type=number] {
  -moz-appearance:textfield;
}

.MuiCheckbox-root.Mui-checked svg {
 
  animation: shrink-bounce 200ms cubic-bezier(.4,.0,.23,1);
}
@keyframes shrink-bounce{
  0%{
    transform: scale(1);
  }
  33%{    
    transform: scale(.85);
  }
  100%{
    transform: scale(1);    
  }
}

/*  Success */

.successGif {
  max-width: 280px;
    border-radius: 50%;
}


iframe.cke_wysiwyg_frame.cke_reset {
  border: 1px solid #D2D3D4 !important;
  border-radius: 0px 0px 4px 4px !important;
}
.cke_contents {
  overflow: inherit !important;
}
.date-picker-custom .MuiIconButton-root {
  margin-right: -9px;
  margin-top: 1px;
}
.verticleAutoSize {
  resize: vertical;
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 20px #ffffff inset !important;
  -moz-box-shadow: 0 0 0 20px #ffffff inset !important;
  -ms-box-shadow: 0 0 0 20px #ffffff inset !important;
  box-shadow: 0 0 0 20px #ffffff inset !important;
  background: #ffffff00 !important;
  filter: none;
  height: 0;
}

.MuiAutocomplete-option {
  min-height: 34px !important;
}
.MuiDatePickerToolbar-root{
  background-color: #1A385E;
  color: white;
}