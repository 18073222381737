/* .cke_button__bold_icon {
background-image: url('../../assets/images/Success.png') !important;
background-size: cover;
} */

body .cke_button__bold_icon {
    background: url(../../assets/images/svg/txteditor/bold.svg) no-repeat !important;
    background-position: center !important;
}
body .cke_button__italic_icon {
    background: url(../../assets/images/svg/txteditor/italic-text.svg) no-repeat !important;
    background-position: center !important;
}
body .cke_ltr .cke_button__bulletedlist_icon {
    background: url(../../assets/images/svg/txteditor/list.svg) no-repeat !important;
    background-position: center !important;
}
body .cke_ltr .cke_button__numberedlist_icon {
    background: url(../../assets/images/svg/txteditor/order-list.svg) no-repeat !important;
    margin-top: -1px !important;
}
body .cke_button__table_icon {
    background: url(../../assets/images/svg/txteditor/grid.svg) no-repeat !important;
    background-position: center !important;
}
body .cke_button__link_icon {
    background: url(../../assets/images/svg/txteditor/link.svg) no-repeat !important;
    background-position: center !important;
    background-size: contain !important;
    
}
body .cke_button__unlink_icon {
    background: url(../../assets/images/svg/txteditor/Minus.svg) no-repeat !important;
    background-position: center !important;
    background-size: contain !important;
}
body .cke_button__horizontalrule_icon {
    background: url(../../assets/images/svg/txteditor/Minus.svg) no-repeat !important;
    background-position: center !important;
}
body .cke_button__textcolor_icon {
    background: url(../../assets/images/svg/txteditor/color.svg) no-repeat !important;
    background-position: center !important;
}
body .cke_button__bgcolor_icon {
    background: url(../../assets/images/svg/txteditor/color.svg) no-repeat !important;
}
.cke_top {
    border-bottom: none !important;
    background: none !important;
}
.cke_bottom {
    display: none !important;
}
.cke_chrome {
    background: none !important;
    border: none !important;
}
.cke_combo_text {
    color: #12A8DE !important;
}
.cke_combo_arrow {
    border-top: 3px solid #12A8DE !important;
}
.cke_combo:after {
    border-right: none !important;
}

body .cke_dialog_ui_hbox .cke_dialog_ui_hbox_first,.cke_dialog_ui_hbox .cke_dialog_ui_hbox_first, .cke_dialog_ui_input_select{
    width: 50% !important;
}
body .cke_dialog_ui_hbox .cke_dialog_ui_labeled_content, .cke_dialog_ui_input_select {
    width: 100% !important;
}
input.cke_dialog_ui_input_text, select.cke_dialog_ui_input_select{
    border-radius: 24px !important;
}
a.cke_dialog_ui_button_ok {
    background: #1A385E !important;
    border: 1px solid #1A385E !important;
    border-radius: 30px !important;
}
a.cke_dialog_ui_button_cancel {
    background: #E6E5E5 !important;
    border: 1px solid #E6E5E5 !important;
    color: #878083 !important;
    border-radius: 30px !important;
}
.cke_dialog_contents_body .cke_dialog_ui_text, .cke_dialog_contents_body .cke_dialog_ui_select, .cke_dialog_contents_body .cke_dialog_ui_hbox_last>a.cke_dialog_ui_button {
    margin-top: 12px !important;
}
@media only screen and (min-width: 576px) {
    .cke_dialog_body {
        width: 480px !important;
    }
    .cke_dialog_contents{
        width: 100% !important;
    }
}
.cke_dialog_contents_body {
    padding: 12px 15px 15px 15px !important;
}
.cke_ltr .cke_dialog_ui_hbox_first {
    padding-right: 15px !important;
}
.cke_dialog_footer {
    padding: 10px 0 !important;
}
a.cke_dialog_ui_button_ok:focus {
    box-shadow: none !important;
}
a.cke_dialog_ui_button_ok {
    width: 100% !important;
}
.cke_dialog_ui_checkbox {
    padding-bottom: 8px !important;
}
input.cke_dialog_ui_input_text:focus, input.cke_dialog_ui_input_password:focus, input.cke_dialog_ui_input_tel:focus, textarea.cke_dialog_ui_input_textarea:focus, select.cke_dialog_ui_input_select:focus {
    border: 1px solid #1A385E !important;
}